@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700'); 

html{
  scroll-behavior: smooth;
}

 #entry-page{
  margin-top: 5em;
}


 #dashboard {
  border-top: none;
  background-color: #f9fbfa;
  min-height: 300px;
}

  main {
	display: grid;
	grid-template-columns: 15em 1fr;
  grid-template-rows: 70px calc(100vh - 70px);
	max-width: 100em;
	width: 100%;
	margin: 0 auto;
}

/* make it wider for the scorecard */
 main.scorecard {
	display: grid;
	grid-template-columns: 17em 1fr;
  grid-template-rows: 70px calc(100vh - 70px);
	max-width: 100em;
	width: 100%;
	margin: 0 auto;
}

.login-page {
  width: 360px;
  margin: auto;
}

.form-title {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 50px;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 100px auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Helvetica", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.password-input {
    position: relative;
    /* display: inline-block; */
}

input[type="password"] {
    padding-right: 35px; /* To make space for the icon */
}

.eye-icon {
    position: absolute;
    top: 50%;
    width: 8%;
    right: 10px; /* Adjust the position as needed */
    transform: translateY(-50%);
    cursor: pointer;
    fill: grey; /* Change the color of the eye icon */
    transition: fill 0.3s ease; /* Add a smooth transition effect */
}

.eye-icon:hover {
    fill: #007bff; /* Change the color on hover */
}


button {
  font-family: "Helvetica", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #13aa52;
  width: 200px;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin: 10px;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: #109246;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #13aa52;
  text-decoration: none;
}

.input-title {
  font-size: 14px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
}

body {
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body.error input {
  outline: 2px solid red;
}

body.error #errorMessage {
  visibility: visible;
  color: red;
  font-size: 12px;
  height: auto;
}

#errorMessage {
  visibility: hidden;
  height: 0px;
}


/* ENTRY PAGE CSS */

body, h1, h2, p {
    font-family: 'Lato';
}

/* DASHBOARD STYLING */

 .dashboard-container {
  display: grid;
  gap: 30px;
  margin: 50px 20px;
  grid-row: 2;
  grid-column: 2/-1;
  overflow: auto;
}

.title{
  text-transform: uppercase;
  font-size: 46px;
  font-weight: bold;
  margin-top: 41px;
}

.dropdown{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 80px;
  align-items: center;
}
.dropdown label{
  padding-right: 5px;
}
.dropdown select{
  width: 60%;
  margin: 15px;
}
.dropdown-box {
  width: 100%; 
  margin: auto;
}

.competitors_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.competitors_row img{
  height: 100px;
}

.box {
  position: relative;
  border: 3px solid #0091ea;
  padding: 25px;
  background-color: #f1f5f4;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.single_chart {
  width: 100%;
  padding: 10px;
  position: relative;
}

.sbs_chart {
  width: 47%;
  padding: 10px;
  position:relative;
}

/* For Message Index */
.sample-message{
  font-size: 20px;
  width: 100%;
  text-align: left;
}

.message-type{
  font-weight: bold;
  color:dodgerblue;
}

.box-header {
  font-size: 1.25rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;

  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -30px;
  font-weight: bold;

  transform-origin: 0 0;
  transform: rotate(270deg);
}

#chart-title {
  position: absolute;
  top: 0%;
  left:25px;
  width:100%;
  padding:10px;
  color: dodgerblue;
  font-size: 24px;
  text-align: left; 
}


/* HEADER Navigation */

.header{
  grid-row: 1;
  grid-column: 1 /-1;
  position: sticky;
  top: 0;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  z-index: 1;
  background-color: white;
}


.header a {
  color: black;
  text-align: center;
  padding: 55px;
  text-decoration: none;
  font-size: 16px;
  /* border-radius: 4px; */
}

.header-content{
  margin-top: -15px;
}

.header img {
  max-width: 19%;
  float:left;
  margin-left: 30px;
}

.header a:hover {
  color: dodgerblue;
}

.header a.active {
  color: dodgerblue;
}

/* Playbook Navigation */
.playbook-links {
  display: flex;
  justify-content: space-around;
  margin: 0px;
}

.playbook-links a {
  text-decoration: none;
  color: black;
  font-size: 20px;

  background-color: white;
  border: 2px solid black;
  border-radius: 13px;
  padding: 13px;
}

.playbook-links a.active {
  background-color: dodgerblue;
}

.playbook-links a:hover {
  background-color: dodgerblue;
}

/* Sidebar Navigation */

.section-nav li.active > a {
  background-color: black;
  color: white;
  border: 3px solid transparent;
  border-radius: 13px;
  padding: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.section-nav {
  position: sticky;
  margin-top: 4em;
  align-self: start;
  grid-row: 2;
  padding-right: 0;
  border-right: 2px solid darkgray;
  /* overflow: auto; */ /* adds scroll bar if necessary */
}


.section-nav li.active > a {
  background-color: black;
  color: white;
  border: 3px solid transparent;
  border-radius: 13px;
  padding: 11px;
}

.section-nav {
  position: sticky;
  margin-top: 4em;
  align-self: start;
  grid-row: 2;
  padding-right: 0;
  border-right: 2px solid darkgray;
  /* overflow: auto; */ /* adds scroll bar if necessary */
}

.section-nav img {
  height: 125px;
  justify-content: space-around;
}

.section-nav a {
  text-decoration: none;
  display: block;
  border: 1px solid black;
  padding: 11px;
  color: black;
  border-radius: 13px;
  transition: all 50ms ease-in-out;
}

.section-nav h3 {
  font-size: small;
  margin: 0;
  text-align: left;
}

.section-nav p {
  font-size: small;
  text-align: left;
  margin: 5px 0 0 0;
}

.section-nav a:hover,
.section-nav a:focus {
  background-color: black;
  color: white;
  border: 3px solid transparent;
  border-radius: 13px;
  padding: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 1vh 10px;
  border-radius: 15px;
}

/* Scorecard Styling */

.section-nav button.scorecard-button {
  background-position: left center; /* move background picture to the left */
  background-color: black;
  padding-left: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70px; 
  width: 220px;
  border-radius: 13px;
  box-sizing: border-box;
}

.section-nav button.scorecard-button:hover {
  outline: 2px solid dodgerblue; 
}

.section-nav button.scorecard-button:active {
  outline: 2px solid dodgerblue; 
}

/* .section-nav#scorecard button.active {
  outline: 2px solid dodgerblue;
} */